.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 10;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  .exitBtn {
    color: lightgray;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 900;
    cursor: pointer;
    font-size: 15px;
  }
}
