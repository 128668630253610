@import "../../styles/app.scss";
.kycReview {
  background-color: #2e3342;
  width: fit-content;
  padding: 2%;
  max-height: 85vh;
  min-width: 290px;
  overflow-y: scroll;
  .doc_info {
    margin: 10px 0;
  }
  .doc_imgs {
    text-align: center;
    position: relative;
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    .img_container {
      margin: 0 10px;
    }
    p {
      margin: 10px 0;
    }
  }

  .doc_verification {
    margin: 10px 0;
    input {
      margin: 10px;
    }
  }
  .mainBtns {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: white;
      font-size: 12px;
      padding: 10px;
      width: 15%;
      min-width: fit-content;
      border: none;
      border-radius: 5px;
      margin: 10px;
    }
    .approve_btn {
      background-color: lightgreen;
      cursor: pointer;
    }
    .reject_btn {
      background-color: red;
      cursor: pointer;
    }
  }
}
.none {
  background-color: rgba($color: #000000, $alpha: 0.4);
  cursor: none;
}

.rejectReason {
  display: flex;
  .inputContainer {
    padding: 0 20px;
    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #c1165f;
      color: white;
      outline: none;
    }
    p {
      font-size: 12px;
      color: red;
    }
  }
}
