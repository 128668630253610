@import "../../styles/app.scss";
.link {
  color: inherit;
  text-decoration: none;
}
.navbar {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #191c24;
  color: white;
  .nav__left {
    padding: 0 10px;
    display: flex;
    align-items: center;
    flex: 1;
    .sidebar__toggle {
      cursor: pointer;
    }
    input {
      background-color: transparent;
      border: 1px solid gray;
      border-radius: 5px;
      margin-left: 15px;
      width: 40%;
      height: 30px;
      color: gray;
      @include mobile {
        display: none;
      }
    }
  }
  .nav__right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    button {
      flex: 1;
      height: 30px;
      background-color: #595;
      border: none;
      border-radius: 5px;
    }
    .nav__icons {
      flex: 1;
      display: flex;
      justify-content: space-around;
      color: white;

      .css-i4bv87-MuiSvgIcon-root {
        color: white;
      }
      @include mobile {
        display: none;
      }
    }
    .nav__account {
      flex: 1;
      margin-left: 20px;
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;
        @include mobile {
          display: none;
        }
      }
      .account__info{
        display: none;
    }
      &:hover{
          .account__info{
              display: flex;
           flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              top:5%;
              right: 5%;
              z-index: 12;
              width:200px;
              height: 300px;
              background-color: #000;
              p{
                  z-index: 1;
                  color: aliceblue;
              }
              .border{
                  border-top: #191c24 solid 3px;
                  width: 100%;
              }
              .navbar__options{
                width: 100%;
                .navbar__option{
                  display: flex;
                  background-color: #191c24;
                  border: none;
                  border-bottom: 1px solid #000;
                  cursor: pointer;
                  justify-content: center;
                  align-items: center;
                  padding: 10px 0;
                  height: 20px;
                  width: 100%;
                  &:hover{
                    background-color: #595;
                    padding: 20px 0;
                  }
                }
              }
          }
      }
    }
  }
}
