@import "./app.scss";
.login {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .logo__section {
    margin-top: 10vh;
    .logo {
      height: 140px;
      object-fit: contain;
      @include mobile {
        height: 50px;
      }
    }
  }
  form {
    width: 30%;
    @include mobile {
      width: 80%;
    }
    h2 {
      font-size: 24px;
      margin: 10px 38%;
      text-align: center;
    }
    button {
      margin: 10px 37%;
      padding: 5px;
      background-color: #c1165f;
      border: none;
      color: white;
      width: 25%;
      height: 40px;
      cursor: pointer;
      &:hover {
        background-color: rgba(193, 22, 95,0.8);
      }
    }
  }
}
