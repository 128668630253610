.App {
  height: 100vh;
  overflow-x: hidden;
  color: white;
  body {
    animation: transitionIn 1s;
    .link {
      color: inherit;
      text-decoration: none;
    }
  }
}
@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin tab {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

.sweetalert{
  background-color: #162534;
  .swal-title{
    color: #c1165f;
  }
  .swal-text{
    color: #fff;
  }
}

.scrollHandler {
  width: 90%;
  @include tab {
    overflow-x: scroll;
  }
}

.None{
  display: none;
}

.confirmation{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
    width: 50vw;
    height: 100px;
}

.verticalScroll{
  height: 100%;
  max-height: 60vh;
  overflow-y: scroll;
}

.Relative{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  height: 60px;
}
.AlignRight{
  position: absolute;
  display: flex;
  width: fit-content;
  min-width: 200px;
  right: 0;
  align-items: center;
}
.AlignCenter{
  display: flex;
  width: 100%;
  justify-content: center;
}

.Padding{
  padding: 15px 0;
}
.FlexAlignFlexEnd{
  height: 100%;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

form{
  text-align: left;
  label{
    font-size: 12px;
    margin: 5px;
    padding: 5px;
  }
  .required{
    color: red;
  }
}

.formContainer{
  display: flex;
  flex-wrap: wrap;

}

.inputHolder{
  height: 60px;
  width: 180px;
  padding: 5px;
  margin: 0 5%;
}

.approve_btn {
  background-color: lightgreen;
  cursor: pointer;
  margin: 0 20px;
  padding: 5px 15px;

}
.reject_btn {
  background-color: red;
  cursor: pointer;
  padding: 5px 15px;
  margin: 0 20px;
}

input{
  margin: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  color: white;
  border-bottom: 1px solid #c1165f;
}

input[type="date"] { 
  background: transparent;
  border: none;
  height: 30px;
  width: fit-content;
  border-bottom: 1px solid #c1165f;
  color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

select{
  background-color: transparent;
  height: 30px;
  color: white;
  border: none;
  border-bottom: #c1165f 1px solid;
  outline: none;  
  margin: 5px;
  padding: 5px;
  width: 100%;
  option{
    background-color:black;
  }
}


.loader-wrapper{
  margin: 25vh auto;
  width: 60px;
  height: 60px;
}
.loader{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 10px solid #162534;
  border-top-color:#4bc8eb ;
  border-bottom-color:#c1165f;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.loader-inner{
  border-top-color:#36f372 ;
  border-bottom-color: #fff;
  animation-duration: 2.5s;
}

@keyframes rotate{
  0%{
    transform: scale(1) rotate(360deg);
  }
  50%{
    transform: scale(.8) rotate(-360deg);
  }
  100%{
    transform: scale(1) rotate(360deg);

  }
}

// Search animations
.search-box{
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  line-height: 15px;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;

  &:hover{
    background-color: #162534;
  }
}

.search-input {
  margin: 0;
  padding: 0;
  color: #fff;
  outline: none;
  border: none;
  line-height: 15px;
  width: 0px;
  float: left;
  font-size: 1em;
  transition: 0.7s ease;
  &:focus{
    width: 200px;
    background-color:#162534 ;
  }
}

.search-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #24a0ed;
  padding: 5px;
  border-radius: 50%;
  float: right;
  color: #c1165f;
  transition: 0.4s ease;
}

.search-box:hover{
  
  .search-input, .search-input:focus{
    width: 200px;
    margin: 0 8px;
  }
}

.search-box:hover{
  .search-btn, .search-input:focus + .search-btn{
    background: #4bc8eb;
    color: #101010;
  }
}

::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1165f;
  border-radius: 5px;
}

.body {
  display: flex;
  width: 100vw;
  .bodyContainer {
    width: 100%;
    color: white;
  }
}
.MuiInput-root {
  color: #fff !important;
  padding: 5px 5px;
  &::before {
    border-bottom: 1px solid rgba(193,22,95, 0.7) !important;
  };
  &::after {
    border-bottom: 2px solid rgb(193,22,95) !important;
  };
}
.MuiInputLabel-root {
  color: rgba(193,22,95,0.7) !important; 
}
.Mui-disabled {
  cursor: not-allowed !important;
}
.MuiToolbar-root {
  color: white;
}
.MuiButtonBase-root {
  color: white;
}

.modalImg{
  max-height: 80vh;
  max-width: 80vw;
  object-fit: contain;
  min-height: 20vh;
  min-width: 50vw;
}

.flex-divider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .left{
    width: 50%;
  }
  .right{
    width: 50%;
  }
}

.depositScreenShot{
  width: 50%;
  // aspect-ratio: 1;
}