.grid-row {
  display: grid;
  background-color: #2e3342;
  width: 100%;
  min-width: 800px;
  margin-bottom: 10px;
  height: 50px;
  grid-auto-rows: 50px auto;
  grid-gap: 10px;
}
.head {
  background-color: #c1165f;
}
.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.row-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  overflow-x: auto;
}
