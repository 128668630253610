@import "./app.scss";
.grid-container {
  .MuiSvgIcon-root {
    color: white;
  }
  width: 96%;
  margin: 1.5vw;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: minmax(50px, auto);
  grid-gap: 1.5vw;
  .home-grid-item {
    background-color: #191c24;
    border-radius: 5px;
  }
  .item-1 {
    grid-row: span 2 / span 2;
    grid-column: span 12 / span 12;
    background-color: #c1165f;
  }
  .item-2,
  .item-3,
  .item-4,
  .item-5 {
    grid-row: span 2 / span 2;
    grid-column: span 3 / span 3;
    overflow-y: hidden;
    cursor: pointer;
    
    @include mobile {
      grid-column: span 6 / span 6;
    }
  }

  .item-2,
  .item-5{
    p{
      float: right;
      margin-right: 15%;
      font-size: 2rem;
      font-weight: 700;
    }
  }
  .item-3,
  .item-4{
    .holder{
      float: right;
      width: 50%;
      min-width: 150px;
    }
    p{
      float: right;
      font-size: 1rem;
      font-weight: 700;
      margin-top: -10px;
    }
    small{
      font-size: 16px;
      font-weight: 500;
    }
  }


  .item-6 {
    grid-column: span 4 / span 4;
    grid-row: span 12 / span 12;
    h3{
      padding: 20px;
    }
    .chartContainer{
      width: 70%;
      margin: auto;
      padding: 10px 0;
    }
    @include mobile {
      grid-column: span 12 / span 12;
    }
  }
  .item-7 {
    grid-row: span 6 / span 6;
    grid-column: span 8 / span 8;
    
    @include mobile {
      grid-column: span 12 / span 12;
    }
  }
  .item-8 {
    grid-row: span 3 / span 3;
    grid-column: span 4 / span 4;
    @include mobile{
      grid-column: span 6 / span 6;
    }
  }
  .item-9 {
    grid-row: span 3 / span 3;
    grid-column: span 4 / span 4;
    @include mobile{
      grid-column: span 6 / span 6;
    }
  }
  .item-10,.item-13 {
    grid-row: span 3 / span 3;
    grid-column: span 4 / span 4;
    @include mobile{
      grid-column: span 6 / span 6;
    }
  }
  
  .item-11 {
    grid-row: span 8 / span 8;
    grid-column: span 12 / span 12;
  }
  .item-12{
    grid-row: span 6 / span 6;
    grid-column: span 6 / span 6 ;
    h3{
      padding: 20px;
      text-align: center;
      font-size: 1rem;
    }
  }
}

h3{
  height: 8%;
  text-align: center;
  font-size: 24px;
  padding: 1%;
}

.orderSorter{
  display: flex;
  width: 98%;
  align-items: center;
  height: 10%;
  margin: 15px 0;
  .selectHolder{
    margin: 0 1%;
    width: 24%;
    small{
      color: #c1165f;
      font-size: 12px;
      padding: 0 7px;
    }
  }
}