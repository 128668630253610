.kyc__body {
  width: 96%;
  margin: 1.5vw;
  display: flex;
  flex-direction: column;
  .MuiToolbar-root {
    color: white;
  }
  .MuiButtonBase-root {
    color: white;
  }
  .MuiSvgIcon-root {
    color: white;
  }
  .grid-row {
    display: grid;
    grid-template-columns: repeat(16, minmax(0, 1fr));
    grid-auto-rows: minmax(50px, auto);
    margin: 5px 0;
    cursor: pointer;
  }
  .heading {
    color: #c1165f;
  }
  .grid-item {
    background-color: #191c24;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow-x: hidden;
  }
  .first-item {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: center;
  }
  .last-item {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .grid-span-2 {
    grid-column: 2 span / 2 span;
  }
  .grid-span-3 {
    grid-column: 3 span / 3 span;
  }
}

.SelectHandler{
  float: left;
  margin: 0 5px;
  width: min-content;
  min-width: 100px;
  margin-top: -10px;
    small{
    padding: 0 5px;
    margin: 0;
    font-size: 10px;
  }
}