@import "./app.scss";
.topicSelect {
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic__logo {
    font-size: 60px;
    margin-top: 3vh;
    .logo_img {
      height: 200px;
      object-fit: contain;
      @include mobile {
        height: 50px;
      }
    }
  }
  .logoutBtn {
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }
  .topic__info {
    width: 60%;
    margin: 1vh;
    color: gray;
    font-weight: 600;
    @include mobile {
      width: 90%;
    }
  }
  .grid-container {
    display: grid;
    width: 60%;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-auto-rows: minmax(50px, auto);
    grid-gap: 2vw;
    @include mobile {
      width: 90%;
    }
    .link {
      grid-column: 2 span / 2 span;
      height: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: inherit;
      padding-bottom: 5px;
      background-color: #191919;
      @include mobile {
        grid-column: span 3 / span 3;
        padding: 0;
        height: 140px;
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 11px;
        }
      }
    }
  }
}
