@import "./app.scss";
.sidebar__link {
  display: flex;
  padding: 5px 0 5px 15px;
  align-items: center;
  width: 85%;
  height: 46px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  .iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background-color: #2a2d3a;
    color: gray;
  }
  .name {
    color: gray;
    font-weight: 400;
  }
  &:hover {
    background-color: #000000;
    .name {
      color: white;
    }
  }
  &.selected {
    border-left: 4px solid blue;
    background-color: #000000;
    .name {
      color: white;
    }
  }
}

.sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  width: 300px;
  background-color: #191c24;
  transition: 0.4s;
  @include mobile {
    min-width: 244px;
  }
  .sidebar__logo {
    height: 70px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .full_logo {
      height: 30px;
      object-fit: contain;
    }
    .half_logo {
      opacity: 0;
      height: 30px;
      object-fit: contain;
    }
  }
  .sidebar__account {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    .left {
      flex: 7;
      display: flex;
      .avatar__holder {
        padding: 0 15px 0 0;
      }
      p {
        color: gray;
      }
      strong {
        color: white;
      }
    }
    .right {
      flex: 1;
      .iconHolder {
        color: gray;
      }
    }
  }
  .sidebar__optionHeader {
    padding: 10px 15px;
    h5 {
      color: gray;
      font-size: 0.8rem;
    }
  }
  &.sidebar__collapsed {
    width: 70px;
    background-color: #191c24;
    .account__info {
      display: none;
    }
    .name {
      display: none;
    }
    .full_logo {
      display: none;
    }
    .half_logo {
      opacity: 1;
      height: 30px;
      object-fit: contain;
    }
    .right {
      display: none;
    }
    .sidebar__optionHeader {
      display: none;
    }
    @include mobile {
      display: none;
    }
  }
}
