@import "../app.scss";
.approvedUser {
  background-color: #191c24;
  padding: 20px;
  width: 25vw;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mobile {
    width: 85vw;
  }
  h2 {
    text-align: center;
    padding: 20px;
    color: #c1165f;
  }
  .approvedUser_info {
    width: 100%;
  }
  p {
    font-size: 14px;
    margin: 10px 0;
    padding: 10px 0;
    position: relative;
    b {
      position: absolute;
      left: 5%;
    }
    span {
      position: absolute;
      right: 5%;
    }
  }
}
button {
  background-color: #24a0ed;
  border: none;
  margin: 10px auto;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.Container {
  width: 95%;
  background-color: #191c24;
  border-radius: 5px;
  min-height: 40px;
  margin: 3%;
}
.actionBtn {
  border: none;
  padding: 5px;
  background-color: #24a0ed;
  color: white;
  width: 70%;
  font-size: 14px;
}
.TableContainer {
  margin: 2vh auto;
  width: 96%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.MapContainer {
  width: 100%;
}
