@import "./app.scss";

.Operations {
  height: 7vh;
  @include mobile {
    height: 14vh;
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3vw;
  border-radius: 5px;
  button {
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    color: white;
    background-color: #595;
    border: none;
  }
  .disabled{
    background-color: #2e3342;
    cursor: no-drop;
  }
}
h1 {
  padding: 20px;
  color: #c1165f;
  text-align: center;
}
h2 {
  font-size: 24px;
  font-weight: bold;
  color: #c1165f;
  padding: 10px;
}
p {
  padding: 5px 15px;
  font-size: 14px;
}
.MainBody {
  padding: 10px;
  min-height: 20vh;
  background-color: #191c24;
  border-radius: 5px;
  margin: 3vw;
}
.table-container {
  background-color: black;
  margin: 3vw;
  padding: 10px 0;
  border-radius: 5px;
}
.flex-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.scrollHandler {
  width: 90%;
  @include tab {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.mapContainer {
  width: 100%;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.tab-container {
  background-color: #2e3342;
  text-align: center;
  min-width: 250px;
  max-width: 85vh;
  width: 90%;
  margin: 15px auto;
  border-radius: 5px;
  padding: 15px;
}
.Container {
  position: relative;
  padding: 5px 0;
}
.tab-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.tab-container-list {
  background-color: #2e3342;
  margin: 15px;
  border-radius: 5px;
  padding: 15px;
  min-width: 250px;
}